import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Fullscreen} from './fullscreen';
import {BannerService} from '../../../banner/banner.service';
import {forkJoin, from, of} from 'rxjs'
import {catchError, map, shareReplay} from 'rxjs/operators';

import {PlayerSelectAudioComponent} from './player-select-audio/player-select-audio.component';
import {PlayerSelectVideoComponent} from './player-select-video/player-select-video.component';
import {PlayerSelectTextComponent} from './player-select-text/player-select-text.component';
import {Dialog} from '@angular/cdk/dialog';
import {PlayerSelectTypeComponent} from './player-select-type/player-select-type.component';


declare const clpp: any;

const DASH_STREAM = 'DASH stream';
const HLS_STREAM = 'HLS stream';
const AUTO_SELECT = 'Autoselect';


export const STREAM_TYPES = [HLS_STREAM, DASH_STREAM, AUTO_SELECT]


@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit, OnDestroy {
  @Input() manifest: string;
  @Input() m3u8: string;
  @Input() drmtodayToken: string;
  @Input() accessToken: string;
  @Input() abToken: string;


  player: any;
  error: any;
  airline: string;
  playerState = {
    mouseMoved: false,
    isPlaying: false,
    progress: 0,
    duration: 0,
    isSeeking: false,
    isSelectingTrack: false,
    isFullscreen: false,
    isLoading: true,
    isInitializing: true
  }
  fullscreenManager: Fullscreen;

  videoRenditions: any[] = [];
  audioRenditions: any[] = [];
  textRenditions: any[] = [];

  activeVideoRendition: any; // clpp.Rendition
  activeAudioRendition: any; // clpp.Rendition
  activeTextRendition: any; // clpp.Rendition
  selectedStreamType = AUTO_SELECT
  progressBarContainer: HTMLElement;

  token: any;

  streamType = ''
  streamTypeDASH = false
  streamTypeHLS = false

  mouseMoveTimeout = null;

  constructor(
    private route: ActivatedRoute,
    private banner: BannerService,
    private cdRef: ChangeDetectorRef,
    private dialog: Dialog
  ) {
    this.airline = this.route.snapshot.params['airline'];
  }

  startMouseMovedTimer = () => {
    console.log('start mouse moved timer')
    this.playerState.mouseMoved = true;
    this.cdRef.detectChanges();
    clearTimeout(this.mouseMoveTimeout);
    this.mouseMoveTimeout = setTimeout(() => {
      this.playerState.mouseMoved = false;
      this.cdRef.detectChanges()
    }, 2000);
  }

  initFullscreen = () => {
    const playerContainer = document.getElementById('outer-container');
    this.fullscreenManager = new Fullscreen(
      playerContainer,
      document,
      () => {
        this.playerState.isFullscreen = this.fullscreenManager.fullscreenEnabled();
      },
    );
  }

  loadPlayer() {

    const fpObs = from(navigator.requestMediaKeySystemAccess('com.apple.fps', [{
      'initDataTypes': ['cenc'],
      'audioCapabilities': [{
        'contentType': 'audio/mp4;codecs="mp4a.40.2"'
      }],
      'videoCapabilities': [{
        'contentType': 'video/mp4;codecs="avc1.42E01E"'
      }]
    }])).pipe(map(() => true), catchError(() => of(false)))

    const prObs = from(navigator.requestMediaKeySystemAccess('com.microsoft.playready', [{
      'initDataTypes': ['cenc'],
      'audioCapabilities': [{
        'contentType': 'audio/mp4;codecs="mp4a.40.2"'
      }],
      'videoCapabilities': [{
        'contentType': 'video/mp4;codecs="avc1.42E01E"'
      }]
    }])).pipe(map(() => true), catchError(() => of(false)))

    const wvObs = from(navigator.requestMediaKeySystemAccess('com.widevine.alpha', [{
      'initDataTypes': ['cenc'],
      'audioCapabilities': [{
        'contentType': 'audio/mp4;codecs="mp4a.40.2"'
      }],
      'videoCapabilities': [{
        'contentType': 'video/mp4;codecs="avc1.42E01E"'
      }]
    }])).pipe(map(() => true), catchError(() => of(false)))

    const prRobustnessObs = from(navigator.requestMediaKeySystemAccess('com.microsoft.playready', [{
      'initDataTypes': ['cenc'],
      'audioCapabilities': [{
        'robustness': '2000',
        'contentType': 'audio/mp4;codecs="mp4a.40.2"'
      }],
      'videoCapabilities': [{
        'robustness': '2000',
        'contentType': 'video/mp4;codecs="avc1.42E01E"'
      }]
    }])).pipe(map(() => true), catchError(() => of(false)))

    const wvRobustnessObs = from(navigator.requestMediaKeySystemAccess('com.widevine.alpha', [{
      'initDataTypes': ['cenc'],
      'audioCapabilities': [{
        'robustness': 'SW_SECURE_CRYPTO',
        'contentType': 'audio/mp4;codecs="mp4a.40.2"'
      }],
      'videoCapabilities': [{
        'robustness': 'SW_SECURE_CRYPTO',
        'contentType': 'video/mp4;codecs="avc1.42E01E"'
      }]
    }])).pipe(map(() => true), catchError(() => of(false)))
    const obs = forkJoin([prObs, wvObs, prRobustnessObs, wvRobustnessObs, fpObs]).pipe(shareReplay(1))
    obs.subscribe(
      v => {
        if (!(v[2] || v[3] || v[4])) {
          this.banner.open('This browser neither supports PlayReady SL 2000, Widevine SW_SECURE_CRYPTO nor Apple FairPlay . Previews are disabled.', ['Close'])
        }
        if (this.abToken) {
          const networkEngine = this.player.getNetworkEngine();
          const that = this;
          networkEngine.addRequestModifier(function (request) {
            if (request.type === clpp.net.RequestType.MANIFEST) {
              request.headers['token'] = that.abToken;
            } else if (request.type === clpp.net.RequestType.SEGMENT) {
              request.headers['token'] = that.abToken;
            }
          });
        }
        let manifest = '';
        let manifestType = null;
        let preferredDrmSystem = null;
        if (this.selectedStreamType !== AUTO_SELECT) {
          console.log('force stream type', this.selectedStreamType)
          this.streamType = this.selectedStreamType
        } else {
          if (this.m3u8 && this.manifest) {
            if (v[4]) {
              manifest = this.m3u8
              this.streamType = HLS_STREAM
            } else {
              manifest = this.manifest
              this.streamType = DASH_STREAM
            }
          } else if (this.m3u8 && !this.manifest) {
            if (v[4]) {
              this.streamType = HLS_STREAM
            } else {
              this.banner.open('Preview is only available as HLS. Please preview with a Safari browser', ['Close'])
            }
          } else if (!this.m3u8 && this.manifest) {
            if (v[2] || v[3]) {
              this.streamType = DASH_STREAM
            } else {
              this.banner.open('Preview is only available as DASH. Please preview with a Chrome or Edge browser', ['Close'])
            }
          }
        }
        if (this.streamType === DASH_STREAM) {
          manifest = this.manifest
          manifestType = clpp.Type.DASH
          preferredDrmSystem = clpp.drm.KeySystem.WIDEVINE
          this.streamTypeDASH = true
          this.streamTypeHLS = false
          clpp.install(clpp.dash.DashComponent);
        } else if (this.streamType === HLS_STREAM) {
          manifest = this.m3u8
          manifestType = clpp.Type.HLS
          preferredDrmSystem = clpp.drm.KeySystem.FAIRPLAY
          this.streamTypeDASH = false
          this.streamTypeHLS = true
          clpp.install(clpp.hls.HlsComponent);
        }
        console.log(manifest)
        const playerInit = {
          source: {
            url: manifest,
            type: manifestType
          },
        }
        if (this.drmtodayToken) {
          const payload = JSON.parse(atob(this.drmtodayToken.split('.')[1]))
          const optData = JSON.parse(payload['optData'])
          playerInit['drm'] = {
            env: 'DRMtoday',
            preferredDrmSystem: preferredDrmSystem,
            emeApi: clpp.drm.eme.EmeFactory.Apis.STANDARD,
            customData: {
              userId: optData['userId'],
              sessionId: optData['sessionId'],
              merchant: optData['merchant'],
              authToken: this.drmtodayToken
            }
          }
        }
        this.player.load(playerInit);
      })
    return obs

  }

  ngOnInit() {
    const prestoPlayerScripts = [
      'assets/clpp-6.17.0/cl.core.js',
      'assets/clpp-6.17.0/cl.mse.js',
      'assets/clpp-6.17.0/cl.dash.js',
      'assets/clpp-6.17.0/cl.hls.js',
      'assets/clpp-6.17.0/cl.htmlcue.js',
      'assets/clpp-6.17.0/cl.vtt.js',
    ];

    const loadScript = (src) => new Promise(
      (resolve) => {
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = src;
        scriptElement.onload = () => {
          resolve(true);
        };
        document.head.appendChild(scriptElement);
      }
    );
    let promise = loadScript(prestoPlayerScripts[0]);
    prestoPlayerScripts.slice(1).forEach(src => {
      promise = promise.then(() => loadScript(src));
    });
    promise.then(() => {

      this.player = new clpp.Player(
        'player',
        {
          autoplay: false,
          muted: false,
          license: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1cmxzIjpbImh0dHBzOi8vY29udGVudC5jYXN0bGFicy5jb20iLCJodHRwczovL2NvbnRlbnQtc3RhZy5jYXN0bGFicy5jb20iXSwidHlwZSI6IldlYiIsImtpZCI6MjkxMSwiaWx2IjpmYWxzZX0.oPAnJkScgbH6vOomr-tiJS54gMZSyjOHI23nVSppwSeFcdhxT-rbPtgwyZk9Jek9vx_RTZlF5uIx5y5yj8eJINaoWicDt42tIvjLcHz-5yUmaDfb3lPk35Ygj0fsse9uQlTgbTz5X_eAqihx5Ogz4rYccovz807iPgFY8btsi-0gISmVUVeJI5cwLewxyuD91LVjvrofqY8Jaejy86gNk69r2v90Dmp5O5CSyb8Xozq5FZmb6S7kmDacMoxmekFBQxhIgInnLjGHL1l3mpn2bhLoV7z8okkUyg95nbeScsY-qAFNcRUV5cN5sczeFz-owOe9aPHGUuMtHZzPcYyk_Q',
          textstyle: {
            backgroundColor: 'rgba(0,0,0,0)',
            fontFamily: 'Open Sans',
            fontSize: '1em',
            edgeColor: 'dark-gray',
            edgeType: clpp.text.EdgeType.UNIFORM
          }
        },
      );
      clpp.install(clpp.htmlcue.HtmlCueComponent);
      clpp.install(clpp.vtt.VttComponent);

      this.player.on('error', err => {
        this.banner.open(err.detail.message, ['Close'])
        console.log(err)
      })

      this.progressBarContainer = document.getElementById('progress-bar-container');
      this.initFullscreen();



      this.player.on('timeupdate', () => {
        if (!this.playerState.isSeeking) {
          this.playerState.progress = this.player.getPosition();
        }
      });
      this.player.on(clpp.events.LOADEDMETADATA, () => {
        this.playerState.duration = this.player.getDuration();
        this.playerState.isLoading = false;
        this.playerState.isInitializing = false;
        this.player.getTextDisplayer().setFontColor('rgba(235, 235, 235, 0.8)')
        this.cdRef.detectChanges()
        this.startMouseMovedTimer(); // display controls!
      });
      this.player.on(clpp.events.SEEKING, () => {
        this.playerState.isLoading = true;
      })
      this.player.on(clpp.events.SEEKED, () => {
        this.playerState.isLoading = false;
        this.cdRef.detectChanges()
      })
      this.player.on(clpp.events.ERROR, event => {
        this.error = event.detail;
      });
      this.player.on(clpp.events.VIDEO_TRACK_CHANGED, () => {
        this.activeVideoRendition = this.player.getTrackManager().getVideoRendition();
      });
      this.player.on(clpp.events.AUDIO_TRACK_CHANGED, () => {
        this.activeAudioRendition = this.player.getTrackManager().getAudioRendition();
      });
      this.player.on(clpp.events.TEXT_TRACK_CHANGED, () => {
        this.activeTextRendition = this.player.getTrackManager().getTextRendition();
      });
      this.player.on(clpp.events.TRACKS_ADDED, () => {
        const trackManager = this.player.getTrackManager();
        console.log('aaa')
        this.videoRenditions = trackManager.getVideoTracks().map(
          videoTrack => videoTrack.renditions).reduce((prev, curr) => [...prev, ...curr], [])
        this.audioRenditions = trackManager.getAudioTracks().map(
          audioTrack => audioTrack.renditions).reduce((prev, curr) => [...prev, ...curr], [])
        if (this.audioRenditions.length > 0) {
          console.log(this.audioRenditions)
          trackManager.setAudioRendition(this.audioRenditions[0]);
        }
        this.textRenditions = trackManager.getTextTracks().map(
          textTrack => textTrack.renditions).reduce((prev, curr) => [...prev, ...curr], [])
      });

      this.loadPlayer()
    });
  };

  ngOnDestroy(): void {
    this.player.destroy();
  }

  play() {
    console.log('play hit')
    if (this.playerState.isLoading) {
      return;
    }
    this.player.play().then(
      () => {
        this.playerState.isPlaying = true;
      }
    );
  }

  pause() {
    if (this.playerState.isLoading) {
      return;
    }
    this.player.pause().then(
      () => {
        this.playerState.isPlaying = false;
      }
    );
  }

  isVisible() {
    const {isSeeking, mouseMoved, isSelectingTrack, isInitializing} = this.playerState;
    return (isSeeking || mouseMoved || isSelectingTrack) && !isInitializing;
  }

  getProgressPercent() {
    const duration = Math.max(this.playerState.duration, 0.001);
    return (this.playerState.progress / duration * 100).toFixed(1) + '%';
  }

  getFormattedProgress() {
    const {progress, duration} = this.playerState;
    const padZero = num => num.toString().padStart(2, '0');
    const hours = Math.floor(progress / 3600);
    const minutes = Math.floor((progress - 3600 * hours) / 60);
    const seconds = Math.floor(progress - 3600 * hours - 60 * minutes);
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  }

  getFormattedMediaRunTime() {
    const {progress, duration} = this.playerState;
    const padZero = num => num.toString().padStart(2, '0');
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration - 3600 * hours) / 60);
    const seconds = Math.floor(duration - 3600 * hours - 60 * minutes);
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  }


  startSeek(event) {
    const updateProgressOnSeek = (updateProgressOnSeekEvent) => {
      const boundingRect = this.progressBarContainer.getBoundingClientRect();
      this.playerState.progress = this.playerState.duration * Math.max(
        Math.min((updateProgressOnSeekEvent.pageX - boundingRect.left) / boundingRect.width, 1), 0);
    };
    const stopSeek = () => {
      this.playerState.isSeeking = false;
      document.removeEventListener('mousemove', updateProgressOnSeek)
      document.removeEventListener('mouseup', stopSeek);
      this.player.seek(this.playerState.progress);
    };
    document.addEventListener('mouseup', stopSeek);
    document.addEventListener('mousemove', updateProgressOnSeek);
    this.playerState.isSeeking = true;
    updateProgressOnSeek(event);
  }


  selectStreamType() {
    this.playerState.isSelectingTrack = true
    this.dialog.open<string>(PlayerSelectTypeComponent, {
      data: {
        selectedStreamType: this.selectedStreamType,
        streamTypeHLS: this.streamTypeHLS,
        streamTypeDASH: this.streamTypeDASH
      }
    }).closed.subscribe(r => {
      console.log('selected stream type', r);
      if (r) {
        this.selectedStreamType = r;
        this.loadPlayer();
      }
      this.playerState.isSelectingTrack = false;
    });
  }

  selectAudioStream() {
    this.playerState.isSelectingTrack = true;
    this.dialog.open<number>(PlayerSelectAudioComponent, {
      data: {
        renditions: this.audioRenditions,
        activeRenditionId: this.activeAudioRendition.id,
        streamTypeHLS: this.streamTypeHLS,
        streamTypeDASH: this.streamTypeDASH
      }
    }).closed.subscribe(r => {
      this.playerState.isSelectingTrack = false;
      if (r && r >= 0) {
        this.player.getTrackManager().setAudioRendition(this.audioRenditions.filter(ar => ar.id === r)[0], true);
      }
    });
  }

  selectVideoStream() {
    this.playerState.isSelectingTrack = true;
    this.dialog.open<number>(PlayerSelectVideoComponent, {
      data: {
        renditions: this.videoRenditions,
        activeRenditionId: this.activeVideoRendition ? this.activeVideoRendition.id : -1,
        streamTypeHLS: this.streamTypeHLS,
        streamTypeDASH: this.streamTypeDASH
      }
    }).closed.subscribe(r => {
      this.playerState.isSelectingTrack = false;
      if (r && r >= 0) {
        this.player.getTrackManager().setVideoRendition(this.videoRenditions.filter(ar => ar.id === r)[0], true);
      }
    });
  }

  selectTextStream() {
    this.playerState.isSelectingTrack = true;
    this.dialog.open<number>(PlayerSelectTextComponent, {
      data: {
        renditions: this.textRenditions,
        activeRenditionId: this.activeTextRendition ? this.activeTextRendition.id : -1,
        streamTypeHLS: this.streamTypeHLS,
        streamTypeDASH: this.streamTypeDASH
      }
    }).closed.subscribe(r => {
      this.playerState.isSelectingTrack = false;
      if (r && r >= 0) {
        this.player.getTrackManager().setTextRendition(this.textRenditions.filter(ar => ar.id === r)[0], true);
      } else {
        this.player.getTrackManager().setTextRendition(null, true);
      }

    });
  }
}
