import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes, UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup} from '@angular/router';
import {AuthGuard} from './auth.guard';
import {SearchPageComponent} from './components/search/search-page.component';
import {LoginPageComponent} from './components/login-page.component';
import {ListPreviewsForAirlineComponent} from './components/previews/list-previews-for-airline/list-previews.component';
import {ListPosComponent} from './components/workflows/list-pos.component';
import {ListPoItems2Component} from './components/workflows/list-po-items2.component';

import {PreviewsComponent} from './components/previews/previews.component';
import {WorkflowsComponent} from './components/workflows/workflows.component';
import {UserProfileComponent} from './components/user-profile/user-profile.component';
import {PreviewPoitemComponent} from './components/previews/display-poitem-preview/preview-poitem.component';
import {LoggedInComponent} from './components/logged-in/logged-in.component';
import {FolderComponent} from './components/s3-browse/folder/folder.component';
import {MfaGuard} from './mfa.guard';
import {OrganizationSwitcherComponent} from './components/organization-select/organization-switcher.component';
import {FileDetailsComponent} from './components/s3-browse/file-details/file-details.component';
import {HomeComponent} from './components/s3-browse/home-component/home.component';
import {OrganizationSelectedComponent} from './components/organization-selected/organization-selected.component';
import {policiesResolver} from './policy-resolver.resolver';
import {organizationsResolver} from './organizations-resolver.resolver';
import {organizationResolver} from './organization-resolver.resolver';
import {airlinesResolver} from './airlines.resolver';
import {airlineResolver} from './airline.resolver';
import {licensedServicesResolver} from './licensed-services.resolver';

const customMatcher: UrlMatcher = (
  segments: UrlSegment[],
  group: UrlSegmentGroup,
  route: Route
): UrlMatchResult => {
  const idPaths = segments.map(segment => segment.path);

  const mergedId = idPaths.join('/');
  const idSegment: UrlSegment = new UrlSegment(mergedId, {assetpath: mergedId});
  return ({consumed: segments, posParams: {assetpath: idSegment}});
};

const routes: Routes = [
  {path: 'login', pathMatch: 'full', component: LoginPageComponent},
  {
    path: '',
    component: LoggedInComponent,
    resolve: {organizations: organizationsResolver},
    canActivate: [AuthGuard],
    children: [
      {path: '', pathMatch: 'full', component: OrganizationSwitcherComponent},
      {path: 'userprofile', pathMatch: 'full', component: UserProfileComponent},
      {
        path: 'o/:organization_id',
        canActivate: [MfaGuard],
        resolve: {
          organization: organizationResolver,
          policies: policiesResolver,
          airlines: airlinesResolver,
          licensedServices: licensedServicesResolver
        },
        runGuardsAndResolvers: 'always',
        component: OrganizationSelectedComponent,
        children: [
          {path: '', pathMatch: 'full', redirectTo: 'files'},
          {
            path: 'files',
            children: [
              {
                path: '', pathMatch: 'full', component: HomeComponent,
              },
              {
                path: 'details', children: [{
                  matcher: customMatcher,
                  children: [
                    {path: '', pathMatch: 'full', component: FileDetailsComponent},
                  ]
                },
                ]
              },
              {
                path: 'browse', children: [{
                  matcher: customMatcher,
                  children: [
                    {path: '', pathMatch: 'full', component: FolderComponent},
                  ]
                },
                ]
              },

            ]
          },
          {path: 'search', pathMatch: 'full', component: SearchPageComponent, canActivate: [MfaGuard]},
          {
            path: 'previews', canActivate: [MfaGuard], children: [
              {path: '', pathMatch: 'full', component: PreviewsComponent},
              {path: ':airline', pathMatch: 'full', redirectTo: ':airline/', resolve: {airline: airlineResolver}},
              {path: ':airline/:po', pathMatch: 'full', component: ListPreviewsForAirlineComponent, resolve: {airline: airlineResolver}},
              {
                path: ':airline/:po/:poitem/preview',
                pathMatch: 'full',
                component: PreviewPoitemComponent,
                resolve: {airline: airlineResolver}
              },
            ]
          },
          {
            path: 'workflows',
            canActivate: [MfaGuard],
            children: [
              {path: '', pathMatch: 'full', component: WorkflowsComponent},
              {path: ':airline', pathMatch: 'full', component: ListPosComponent, resolve: {airline: airlineResolver}},
              {path: ':airline/:po', pathMatch: 'full', component: ListPoItems2Component, resolve: {airline: airlineResolver}},
              {
                path: ':airline/:po/:poitem/preview',
                pathMatch: 'full',
                component: PreviewPoitemComponent,
                resolve: {airline: airlineResolver}
              },
            ]
          },
        ]
      },
    ],
  },
  {path: '**', redirectTo: '/'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // enableTracing: true,
    useHash: true,
    initialNavigation: 'disabled',
    bindToComponentInputs: true,
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
