<div style="  position: relative;
  padding-bottom: 56.25%;">

  <div id="outer-container" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
    <div style="height: 100%">
      <div
        class="header-container" style="justify-content: normal"
        [style.opacity]="isVisible() ? 1 : 0">
        <span style="font-size: 32px" *ngIf="streamType">playing {{ streamType }}</span>
        <div style="flex-grow: 1"></div>
        <div style="display: flex; gap: 8px; flex-direction: row">
          <ng-container *ngIf="m3u8 && manifest">
            <i role="button" style="padding-top: 8px; padding-bottom: 8px; padding-left: 8px;"
               class="fa faw fa-cogs"
               (click)="selectStreamType()">

            </i>
          </ng-container>

          <i role="button" style="padding-top: 8px; padding-bottom: 8px; padding-left: 8px;"
             class="fa faw fa-closed-captioning"
             (click)="this.selectTextStream()">

          </i>

          <i role="button" style="padding-top: 8px; padding-bottom: 8px; padding-left: 8px;"
             class="fa faw fa-music"
             (click)="this.selectAudioStream()">
          </i>

          <i role="button" style="padding: 8px"
             class="fa faw fa-video"
             (click)="this.selectVideoStream()">
          </i>
        </div>
      </div>

      <video id="player" crossOrigin="anonymous" style="height: 100%" (mousemove)="startMouseMovedTimer()">
        Your browser does not support HTML5 video.
      </video>

      <div class="footer-container"
           style="display: flex; flex-direction: row; gap: 8px; align-items: center "
           [style.opacity]="isVisible() ? 1 : 0">
        <div style="display: flex; flex-direction: row; gap: 8px; padding-left: 8px">
          <i role="button" class="fas fa-fw fa-step-backward" (click)="player.seek(player.getPosition() - 5)"></i>
          <i role="button" class="fas fa-fw fa-play" *ngIf="!playerState.isPlaying" (click)="play()"></i>
          <i role="button" class="fas fa-fw fa-pause" *ngIf="playerState.isPlaying" (click)="pause()"></i>
          <i role="button" class="fas fa-fw fa-step-forward" (click)="player.seek(player.getPosition() + 5)"> </i>
        </div>
        <div
          id="progress-bar-container"
          class="progress-bar-container"
          (mousedown)="startSeek($event)">
          <div
            class="progress-bar"
            [style.width]="getProgressPercent()">
          </div>
          <div
            class="progress-bar-button"
            [style.visibility]="playerState.isSeeking ? 'visible' : null"
            [style.left]="'calc(' + getProgressPercent() + ' - 6px)'">
          </div>
        </div>
        <div>
          <div style="text-shadow: 0 0 6px darkgrey;">{{ getFormattedProgress() }}</div>
          <div style="text-shadow: 0 0 6px darkgrey;">{{ getFormattedMediaRunTime() }}</div>
        </div>
        <div style="padding-right: 8px">
          <i role="button" *ngIf="!playerState.isFullscreen" class="fas fa-fw fa-expand-arrows-alt"
             (click)="fullscreenManager.requestFullscreen()"></i>
          <i role="button" *ngIf="playerState.isFullscreen" class="fas fa-fw fa-compress-arrows-alt"
             (click)="fullscreenManager.exitFullscreen()"></i>
        </div>
      </div>
    </div>
  </div>
</div>
